.select-box-claim {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 0;
}

.select-box-claim select:focus {
  outline: none;
}

.select-box-claim .arrow {
  position: relative;
}

.select-box-claim .arrow.one:after {
  content: "";
  right: 10px;
}

.select-box-claim .arrow:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: var(--secondary-safety-sky-blue) transparent transparent
    transparent;
  position: absolute;
  right: 21px;
  top: 42%;
  pointer-events: none;
}

.select-box-claim .form-select-claim {
  border-radius: 0;
  padding: 10px 6px;
  font-size: 16px;
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  height: 55px !important;
  font-family: inherit;
  font-weight: 400 !important;
  width: 610px;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 800px) {
  .select-box-claim .form-select-claim {
    width: 103%;
  }
}

.select-box-claim .form-select-claim {
  border-radius: 0;
  padding: 10px 6px;
  font-size: 16px;
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  height: 55px !important;
  font-family: inherit;
  font-weight: 400 !important;
}

.state {
  padding-left: 0;
}

.pe-0 {
  padding-right: 0;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
  padding-left: 0;
}

.col-10 {
  flex: 0 0 auto;
  width: 100%;
}

.form-select-claim {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--secondary-safety-sky-blue);
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  /* background-size:16px 12px; */
  border: 2px solid #ced4da;
  /* border-radius: 0.375rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select-claim {
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  font-family: "Bebas Neue";
  font-weight: 400 !important;
  width: 100%;
  height: 55px !important;
  padding-left: 0.5rem;
  font-size: 15px;
}

.form-select-claim::placeholder {
  color: var(--primary-sea-blue);
  font-size: 15px;
}

.col-8-request-course {
  flex: 0 0 auto;
  width: 65%;
  padding-left: 0;
  color: var(--primary-sea-blue);
}

.claim-disc-form {
  margin-bottom: 0.6rem;
}

.claim-disc-form input {
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  font-family: "Bebas Neue";
  font-weight: 400 !important;
  width: 100%;
  height: 55px !important;
  padding-left: 5px;
  width: 610px;
  border-radius: 0;
  font-size: 15px;
}

@media screen and (max-width: 800px) {
  .claim-disc-form input {
    width: 103%;
    border: none;
  }
}

.claim-disc-form input::placeholder {
  color: var(--primary-sea-blue);
  font-size: 15px;
}

.missingtext {
  color: #005daa;
  font-weight: 600 !important;
  text-shadow: 0px 2px 3px #ffffff4f;
  font-family: "Bebas Neue", sans-serif;
}

@media screen and (max-width: 500px) {
  .info-claim {
    font-size: 1.5rem !important;
    margin-top: -1rem !important;
  }
}

.button-claim-disc-form {
  height: 55px;
  max-width: 610px;
  width: 85%;
  margin-left: 10px;
  margin-bottom: 1rem;
  transition: ease 0.3s;
  border-width: 0.5px;
}

.button-claim-disc-form:hover {
  background-color: var(--secondary-safety-sky-blue);
  border-color: white;
  border-width: 0.5px;
}

.header-claim-disc {
  font-family: "Thiccboi", sans-serif;
  text-transform: uppercase;
  padding-bottom: 1rem !important;
}

@media screen and (max-width: 500px) {
  .header-claim-disc {
    font-size: 1.5rem !important;
    padding-bottom: 1.3rem !important;
  }
}

.header-popup-claimDisc h2 {
  font-size: 1.25rem !important;
}

.popup-surrender-disc h2 {
  text-transform: uppercase;
  margin-bottom: 0 !important;
  padding-bottom: 1rem;
  padding-top: 0.3rem;
}

@media screen and (max-width: 500px) {
  .popup-surrender-disc h2 {
    font-size: 1.2rem !important;
  }
}

.popup-surrender-disc .buttons-rescue-popup {
  gap: 0.5rem;
  margin-top: 0.2rem;
}

.popup-surrender-disc .red-button-popup {
  padding: 0;
}
