.logo-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1.25rem;
  padding-bottom: 0.5rem;
}

.logo {
  height: auto;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 635px) {
  .logo {
    width: 330px;
    transition: 0.5s ease;
  }
  .logo-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
