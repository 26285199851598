.rescue-flow-step {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
}

.rescue-step-one {
    width: 50%;
}