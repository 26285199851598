.button-red-courses-support-ticket {
    margin-top: 10px;
    margin-left: 10px;
    max-width: 600px;
    width: 80%;
    height: 50px;
    color: white;
    border: 2px solid transparent;
    font-family: "Akira Expanded", sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0px;
    padding: 0;
    margin: auto;
    height: 55px;
}

.button-blue-courses-support-ticket {
    margin-top: 10px;
    margin-left: 10px;
    max-width: 600px;
    width: 80%;
    height: 50px;
    color: white;
    border: 2px solid transparent;
    font-family: "Akira Expanded", sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0px;
    padding: 0;
    margin: auto;
}

.button-submit-ticket {
    margin-top: 10px;
    margin-left: 10px;
    max-width: 600px;
    width: 80%;
    height: 50px;
    color: white;
    border: 2px solid transparent;
    font-family: "Akira Expanded", sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0px;
    padding: 0;
    margin: auto;
}

@media screen and (max-width: 500px) {
    .button-red-courses-support-ticket {
        font-size: 0.6rem;
    }

    .button-blue-courses-support-ticket {
        font-size: 0.6rem;
    }
    .input-claim-id {
        width: 80% !important;
    }
}

.button-red-courses-support-ticket:hover {
    background-color: var(--primary-sea-blue) !important;
    border: 2px solid var(--border-primary-blue) !important;
    transition: 0.3s ease;
}

.button-blue-courses-support-ticket:hover {
    background-color: var(--primary-burnt-orange) !important;
    border: 2px solid var(--border-primary-red) !important;
    transition: 0.3s ease;
}

.button-submit-ticket:hover {
    background-color: var(--primary-sea-blue) !important;
    border: 2px solid var(--border-primary-blue) !important;
    transition: 0.3s ease;
}

.main-header-support h4 {
    max-width: 70%;
    margin: auto;
    font-size: 2.5rem
}

.main-header-support .where-request {
    color: var(--primary-sea-blue);
    text-shadow: 0px 2px 3px #ffffff4f;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 500;
}

.main-header-support .secondary-missing-text-request {
    color: var(--primary-white);
    text-shadow: none;
}

@media screen and (max-width: 500px) {
    .main-header-support h4 {
        max-width: 90%;
        margin: auto;
        font-size: 1.5rem;
    }

}