.content-sub-header {
  width: 80%;
}

@media screen and ( max-width:500px ){
  .content-sub-header {
    max-width: 800px;
    width: 80%;
  }
}

.sub-header {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--primary-sea-blue);
  padding: 0px;
  padding: 5px 0px;
  text-align: left;
  font-family: "Thiccboi", sans-serif;
}

@media screen and (max-width: 500px) {
  .sub-header {
    font-size: 1.2rem;
  }
}