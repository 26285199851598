.button-red-courses {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 600px;
  width: 82%;
  height: 50px;
  color: white;
  border: 2px solid transparent;
  font-family: "Akira Expanded", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0px;
  padding: 0;
  margin: auto;
}

.button-blue-courses {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 600px;
  width: 82%;
  height: 50px;
  color: white;
  border: 2px solid transparent;
  font-family: "Akira Expanded", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0px;
  padding: 0;
  margin: auto;
}

@media screen and (max-width: 500px) {
  .button-red-courses {
    font-size: 0.6rem;
  }

  .button-blue-courses {
    font-size: 0.6rem;
  }
}

.button-red-courses:hover {
  background-color: var(--primary-sea-blue) !important;
  border: 2px solid var(--border-primary-blue) !important;
  transition: 0.3s ease;
}

.button-blue-courses:hover {
  background-color: var(--primary-burnt-orange) !important;
  border: 2px solid var(--border-primary-red) !important;
  transition: 0.3s ease;
}
