.app-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  background-color: var(--grey);
}

.loading-logo {
  background: url("../assets/DRN_WebLogo_HDPI.png") no-repeat center center;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  max-width: 600px;
  max-height: 600px;
}

.loading-bar-container {
  position: relative;
  width: 50%;
  height: 20px;
  background-color: #ddd;
  margin-top: 20%;
}

.loading-bar {
  position: absolute;
  height: 100%;
  width: 0;
  background-color: #4caf50;
  transition: width 1.5s;
}
