.report-lost-components {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* margin-top: 0.7rem !important; */
  }
  
  .report-lost-components h2 {
    color: var(--primary-white);
    margin: 0;
    font-size: 3rem;
    padding-bottom: 1.5rem;
  }
  
  .report-lost-components h2 span {
    font-weight: 100;
  }
  
  .button-report-lost {
    height: 55px;
    max-width: 610px;
    width: 85%;
    margin-left: 10px;
    font-family: "Akira Expanded", sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0px;
    padding: 0;
  }

  @media screen and (max-width: 500px) {
    .button-report-lost {
      font-size: 0.6rem;
    }
  }

  .button-report-lost:hover {
    background-color: var(--primary-sea-blue);
  }
