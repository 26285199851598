.fab-container {
  position: fixed;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px;
  z-index: 15;
  background-color: transparent;
  border-radius: 40px;
}

.fab {
  background-color: var(--primary-burnt-orange);
  border: none;
  /* border: 3px solid #e3e3e3; */
  border-radius: 50%;
  color: var(--primary-white);
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
  z-index: 15;
  position: relative;
  height: 65px;
  width: 65px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-fab {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0px;
  padding: 0px;
  object-fit: contain;
  z-index: 12;
}

.fab-menu-container {
  position: fixed;
  bottom: -5%;
  left: 50%;
  transform: translateX(-50%);
  width: 225px;
  height: 225px;
  border-radius: 50%;
  opacity: 0.65;
  background-color: var(--primary-burnt-orange);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 13;
}

.fab-menu-container.active {
  display: flex;
}

.fab-menu-container-mobile {
  position: fixed;
  bottom: -8%;
  left: 50%;
  transform: translateX(-50%);
  width: 225px;
  height: 225px;
  border-radius: 50%;
  opacity: 0.65;
  background-color: var(--primary-burnt-orange);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 13;
}

.fab-menu-container-mobile.active {
  display: flex;
}

.fab-menu {
  position: relative;
  width: 225px;
  height: 2250px;
}

.fab-menu-item {
  position: absolute;
  bottom: 100%;
  width: 120px;
  text-align: center;
  color: var(--secondary-safety-sky-blue) !important;
  font-size: 0.9rem;
  transform: translateX(-50%);
  cursor: pointer;
}

.fab-menu-item-text {
  color: var(--secondary-safety-sky-blue) !important;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.fab-menu-item-text-left {
  color: var(--secondary-safety-sky-blue) !important;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
}

.fab-menu-item-text-right {
  color: var(--secondary-safety-sky-blue) !important;
  margin: 0px;
  padding: 0px;
  text-align: left;
  padding-right: 10px;
}

.fab-menu-item:nth-child(1) {
  left: -10%;
  top: 30%;
  transform: translate(-50%, -50);
  color: var(--secondary-safety-sky-blue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px;
  text-align: left;
  /* height: 125px; */
  width: 125px;
}

.fab-menu-item:nth-child(2) {
  top: -15%;
  left: 50%;
  transform: translate(-50%, 0);
  color: var(--secondary-safety-sky-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px;
  text-align: left;
  height: 125px;
  width: 125px;
}

.fab-menu-item:nth-child(3) {
  top: 30%;
  right: -140px;
  transform: translate(-50%, -50%);
  color: var(--secondary-safety-sky-blue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0px;
  text-align: left;
  /* height: 125px; */
  width: 125px;
}

.fab-menu-item i {
  color: white;
  background-color: var(--secondary-safety-sky-blue);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 0px;
}

.fab-menu-item-icon {
  color: var(--primary-white);
  width: 1rem;
  height: 1rem;
}

footer {
  background-color: var(--primary-sea-blue);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 13px 0 9px;
  overflow: visible; /* Ensure pseudo-element is visible outside the bounds */
}

.secondary-footer {
  background-color: var(--secondary-safety-sky-blue) !important;
}

.footer-content {
  display: flex;
  justify-content: space-between; /* Adjust this to manage spacing */
  align-items: center;
  position: relative; /* Ensure positioning context */
  width: 80%;
}

@media screen and (max-width: 500px) {
  .footer-content {
    width: 100%;
  }
}

/* Add a spacer div for the FAB button */
.footer-content .fab-spacer {
  flex-grow: 1;
}

/* Adjustments for the columns to ensure they are evenly spaced */
.footer-content .col-2 {
  flex: 1; /* Ensure each takes equal space */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  position: relative;
}

/* Specific adjustments if necessary for the icons around the FAB space */
.footer-content .col-2:nth-child(2) {
  margin-right: auto; /* Adjust this value based on your FAB button size */
}

.footer-content .col-2:nth-child(3) {
  margin-left: auto; /* Adjust this value based on your FAB button size */
}

.footer-content span {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-white);
  cursor: pointer;
}

.footer-content img {
  max-width: 25px;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.97);
  z-index: 12;
  bottom: -30%;
}

.overlay.active {
  display: block;
}

@media screen and (max-width: 480px) {
  .footer-content span {
    font-size: 12px;
  }
}

span.img {
  min-height: 30px;
}

.footer-before::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 73px;
  height: 50px;
  background-color: var(--primary-grey);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 36.5px;
  border-bottom-right-radius: 36.5px;
  z-index: -1;
}
