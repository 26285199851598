.select-box-forms {
  max-width: 400px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.select-box-forms select:focus {
  outline: none;
}

.select-box-forms .arrow {
  position: relative;
}

.select-box-forms select {
  border-radius: 0;
  padding: 10px 6px;
  font-size: 16px;
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  height: 50px !important;
  font-family: inherit;
  font-weight: 500 !important;
}

.course-picker-step1 {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 500px) {
  .course-picker-step1 {
    width: 100%;
  }
}

.state {
  padding-left: 0;
}

.pe-0 {
  padding-right: 0;
}

.col-4-forms {
  flex: 0 0 auto;
  width: 33.33333333%;
  max-width: 150px;
}

.col-8-forms {
  flex: 0 0 auto;
  width: 66.66666667%;
  padding-left: 0;
  max-width: 300px;
}

.form-select-rescue-flow {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  /* background-size:16px 12px; */
  border: 2px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (max-width: 500px) {
  .form-select-rescue-flow {
    width: 96%;
  }
}

.select-box-forms .arrow.one:after {
  content: "";
  right: 10px;
}

.select-box-forms .arrow:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: var(--secondary-safety-sky-blue) transparent transparent
    transparent;
  position: absolute;
  right: 21px;
  top: 42%;
  pointer-events: none;
}

.report-lost-class {
  max-width: 600px;
  width: 80%;
  gap: 0.7rem;
  justify-content: flex-start;
}

@media screen and (max-width: 500px) {
  .report-lost-form-select {
    width: 100%;
  }
}

.report-class-col-4 {
  max-width: none;
  width: 33.33333333%;
}

.report-class-col-8 {
  max-width: none;
  width: 66.66666667%;
  padding-left: 0;
}
