button {
  font-family: "Bebas Neue";
  text-align: center;
  border-radius: 0px;
  cursor: pointer;
  letter-spacing: 1.5px;
  height: auto;
  min-height: 30px;
  max-width: 800px;
  width: 100%;
  color: var(--primary-white);
  font-size: 1rem;
}

.btn {
  padding: 13px 25px;
}

@media screen and (max-width: 500px) {
  .btn {
    padding: 10px 20px;
  }
}

.red {
  background-color: var(--primary-burnt-orange) !important;
  border: 2px solid var(--border-primary-red);
}

.red:hover {
  background-color: var(--border-primary-blue) !important;
  border: 2px solid var(--primary-sea-blue) !important;
}

.blue:hover {
  background-color: var(--primary-burnt-orange) !important;
  border: 2px solid var(--border-primary-red);
}

.blue {
  background-color: var(--primary-sea-blue) !important;
  border: 2px solid var(--border-primary-blue) !important;
}

.no-border {
  border: none !important;
}

.no-border:hover {
  border: none !important;
}
