.min-height-35 {
  min-height: 35dvh;
}

.btn-container-components {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px;
}

.button-home {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin: auto;
  min-height: 75px;
  font-weight: 600;
  letter-spacing: 2px;
}

@media screen and (max-width: 500px) {
  .btn-container-components {
    width: 100%;
  }

  .button-home {
    font-size: 1.6rem;
    min-height: 65px;
  }
}

.box {
  width: 80%;
}

.btn-description {
  text-align: center;
  color: var(--primary-sea-blue);
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  padding-top: 0.3rem;
  width: 100%;
  margin-bottom: 0.5rem;
}

.rescue-flow {
  color: var(--primary-burnt-orange);
  cursor: pointer;
}
