@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap&family=Roboto");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap");

/* AKIRA Expanded */
@font-face {
  font-family: "Akira Expanded";
  src: url("./fonts/AkiraExpanded.eot");
  src: url("./fonts/AkiraExpanded.eot?#iefix") format("embedded-opentype"),
    url("./fonts/AkiraExpanded.woff2") format("woff2"),
    url("./fonts/AkiraExpanded.woff") format("woff"),
    url("./fonts/AkiraExpanded.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Thiccboi Bold */
@font-face {
  font-family: "Thiccboi";
  src: url("./fonts/ThiccboiBold.eot");
  src: url("./fonts/ThiccboiBold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ThiccboiBold.woff2") format("woff2"),
    url("./fonts/ThiccboiBold.woff") format("woff"),
    url("./fonts/ThiccboiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeue-Thin.eot");
  src: url("./fonts/BebasNeue-Thin.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BebasNeue-Thin.woff2") format("woff2"),
    url("./fonts/BebasNeue-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeueLight.eot");
  src: url("./fonts/BebasNeueLight.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BebasNeueLight.woff2") format("woff2"),
    url("./fonts/BebasNeueLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bebas Neue";
  src: url("./fonts/BebasNeueRegular.eot");
  src: url("./fonts/BebasNeueRegular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BebasNeueRegular.woff2") format("woff2"),
    url("./fonts/BebasNeueRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,
::after,
::before {
  box-sizing: border-box;
}

:root,
#root {
  --primary-sea-blue: #005daa;
  --border-primary-blue: #3f88c8;
  --dark-primary: #353535;
  --secondary-safety-sky-blue: #3f88c8;
  --primary-black: #000000;
  --border-primary-black: #606060;
  --primary-burnt-orange: #ef3f3f;
  --secondary-red: #d31e47;
  --border-primary-red: #d31e47;
  --primary-white: #ffffff;
  --primary-grey: #f7f7f7;
  --primary-green: #55de1f;
  --table-separator: #dcdcdc;
  --error-red: #ff0000;
  height: 100dvh;
  width: 100dvw;
}

body {
  margin: auto;
  overflow-x: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--primary-grey);
  font-family: "Bebas Neue", sans-serif;
  height: 100dvh;
  width: 100dvw;
  /* max-width: 1300px; */
}

a span {
  color: var(--primary-sea-blue);
  text-decoration: underline;
}

a {
  text-decoration: underline;
  color: var(--primary-green);
}

.lottie-container {
  width: 100%; /* Default full width */
  width: 200px;
  max-width: 200px; /* Control the maximum width */
  margin: 0 auto; /* Ensure it centers vertically if needed */
  padding-left: 67px; /* Adjust the padding to center the animation */
}

@media (min-width: 768px) {
  .lottie-container {
    max-width: 250px; /* Increase the width for tablets and up */
    width: 250px;
    padding-left: 83px; /* Adjust the padding to center the animation */
  }
}

@media (min-width: 1024px) {
  .lottie-container {
    max-width: 300px; /* Increase the width for desktops */
    width: 300px;
    padding-left: 100px; /* Adjust the padding to center the animation */
  }
}

.lottie-container-2 {
  width: 100%; /* Default full width */
  width: 200px;
  max-width: 200px; /* Control the maximum width */
  margin: auto; /* Ensure it centers vertically if needed */
}

@media (min-width: 768px) {
  .lottie-container-2 {
    max-width: 250px; /* Increase the width for tablets and up */
    width: 250px;
  }
}

@media (min-width: 1024px) {
  .lottie-container-2 {
    max-width: 300px; /* Increase the width for desktops */
    width: 300px;
  }
}

.upper-lottie {
  position: relative;
  width: 100%;
}

.skeleton-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

/* App Layout */
.app-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  padding-bottom: 70px;
}

/* Ensure BetaBanner stays at the top */
.app-layout > .beta-banner {
  flex-shrink: 0;
}

/* Main content takes remaining space */
.app-content {
  flex-grow: 1; /* Makes this section grow to fill available space */
  overflow-y: auto; /* Optional: Enable scrolling if content overflows */
}

/* Footer stays at the bottom */
.app-layout > footer {
  flex-shrink: 0;
}

/* .app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  min-height: 100dvh;
  padding-bottom: 120px;
} */

.app-blue {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  min-height: 100dvh;
  padding-bottom: 120px; */
  background-color: var(--border-primary-blue);
}

.logo-and-arrow {
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Center logo horizontally */
  position: relative; /* To position the arrow within the container */
  width: 100%;
  /* margin-top: 1.25em; */
}

/* @media screen and (max-width: 500px) {
  .logo-and-arrow {
    margin-top: 50px;
  }
} */

.logo-and-arrow .arrow-left-icon {
  position: absolute;
  left: 10px; /* Adjust this to control distance from the screen's edge */
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for perfect vertical alignment */
}

.logo-and-arrow .arrow-left-icon-blue {
  position: absolute;
  left: 10px; /* Adjust this to control distance from the screen's edge */
  font-size: 1.5rem;
  color: var(--primary-sea-blue);
  cursor: pointer;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for perfect vertical alignment */
}

.special-label {
  z-index: -1 !important;
}

.claim-disc-table {
  display: grid;
  grid-template-columns: 1fr 2fr; /* First column for labels, second for details */
  /* gap: 1px; */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: fit-content;
}

.claim-disc-row {
  display: contents; /* Makes each row behave like a table row */
}

.claim-disc-cell {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1rem;
  background-color: #f4f4f4; /* Light gray background */
  border-bottom: 1px solid #ddd; /* Adds grid lines between rows */
  border-right: 1px solid #ddd; /* Adds grid lines between columns */
  font-weight: 400;
}

.claim-disc-cell.label {
  font-weight: 400;
}

.claim-disc-cell.content {
  color: #333;
}

.claim-disc-cell:last-child {
  border-right: none; /* Removes border from last column */
}

.claim-disc-row:last-child .claim-disc-cell {
  border-bottom: none; /* Removes border from the last row */
}

.white-text {
  color: var(--primary-white);
}

.center-important {
  justify-content: center !important;
}

h4 {
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.container {
  min-height: 100%;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin: 1rem auto;
  gap: 1rem;
  padding: 20px;
  overflow-x: auto;
  max-width: 90%;
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: 1rem auto;
  gap: 1rem;
  padding: 0px;
}

/* RescueFlow Styles */

.beta-banner {
  background-color: var(--primary-burnt-orange);
  color: var(--primary-white);
  border: none;
  padding: 6px;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  width: 100%;
  height: auto;
  max-height: 50px;
  /* overflow: hidden;
  position: fixed; */
  top: 0px;
  left: 0px;
  font-family: "Bebas Neue", sans-serif;
  /* z-index: 3; */
}

@media screen and (max-width: 500px) {
  .beta-banner {
    font-size: 0.9rem;
  }
}

#reverse {
  background-color: var(--primary-sea-blue);
  width: 100%;
  height: 100dvh;
  overflow-x: none;
  padding-top: 1rem;
  padding-bottom: 100px;
}

.container-blue {
  background-color: var(--primary-sea-blue);
  /* padding: 2rem;
  padding-top: 3rem; */
  padding: 1rem;
  margin: 0px;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100dvh;
}

.container-light-blue {
  background-color: var(--secondary-safety-sky-blue);
  /* padding: 2rem;
  padding-top: 3rem; */
  padding: 1rem;
  margin: 0px;
  width: auto;
  min-width: 100dvw;
  height: auto;
  min-height: 100dvh;
}

.disc-container {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-left: 1.5rem;
}

.footer-content {
  max-width: 1300px;
}

.inner-app-container {
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  /* margin-bottom: 60px; */
}

.arrow-left-icon {
  position: absolute;
  top: 80px;
  left: 20px;
  font-size: 30px;
  color: white;
  padding: 5px;
  cursor: pointer;
}

.arrow-left-icon-request-course {
  position: absolute;
  top: 80px;
  left: 10rem;
  font-size: 30px;
  color: white;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .arrow-left-icon-request-course {
    left: 1.5rem;
  }
}

.wizardbox {
  padding: 25px 0;
  left: 33.5%;
  right: 0;
  position: absolute;
  bottom: 50px;
  display: flex;
}

@media screen and (min-width: 1600px) {
  .wizardbox {
    left: 50%;
    transform: translateX(-270px);
  }
}

.wizardbox img {
  width: 80px;
  max-width: 60px !important;
  padding-top: 15px;
}

.wizardbox p {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-white);
  max-width: 340px;
  text-align: left;
  transform: translate(3px, -11px);
  line-height: 1.5rem;
}

@media screen and (max-width: 500px) {
  .wizardbox {
    left: 30%;
  }

  .wizardbox p {
    max-width: 200px;
    transform: translate(0px, -8px);
    font-size: 13px;
    line-height: 1.2rem;
  }

  .wizardbox img {
    width: 40px;
    padding-top: 35px;
  }
}

@media screen and (max-height: 820px) {
  .wizardbox img {
    width: 40px;
  }

  .wizardbox p {
    font-size: 12px;
    line-height: 1rem;
    transform: translate(0px, 7px);
  }
}

@media screen and (max-height: 760px) {
  .wizardbox img {
    width: 37px;
  }

  .wizardbox p {
    font-size: 12px;
    line-height: 1rem;
    transform: translate(0px, 12px);
  }
}

.container-store {
  background-color: var(--border-primary-blue);
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 20px;
}

@media screen and (max-width: 500px) {
  .container-store {
    min-height: 100%;
    height: auto;
    padding-bottom: 100px;
    margin-top: 50px;
  }
}

.fw-light {
  color: var(--primary-white);
  font-weight: 300;
}

.container-request-course {
  background-color: var(--border-primary-blue);
  padding: 1rem;
  width: 100%;
  height: 100%;
}

.main-section-request {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .main-section-request {
    width: 90%;
  }
}

.container-report-lost-disc {
  background-color: var(--border-primary-blue);
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 90px;
}

.filters-option {
  text-decoration: none;
  color: var(--primary-white);
  display: flex;
  flex-direction: column;
  max-width: 620px;
  width: 85%;
  align-items: end;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  transform: translateY(74px);
  font-size: 19px;
}

.filters-option span:hover {
  color: var(--primary-burnt-orange);
  transition: ease 0.3s;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .filters-option {
    transform: translateY(74px);
    font-size: 18px;
  }
}

/* claim disc */

.filters-option-claim {
  text-decoration: none;
  color: var(--primary-white);
  display: flex;
  flex-direction: column;
  max-width: 620px;
  width: 85%;
  align-items: end;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  transform: translateY(145px);
  font-size: 19px;
}

.filters-option-claim span:hover {
  color: var(--primary-burnt-orange);
  transition: ease 0.3s;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .filters-option-claim {
    transform: translateY(82px);
    font-size: 16px;
  }
}

/* Search Inventory */

.container-search-inventory {
  padding: 1rem;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .container-search-inventory {
    padding-top: 0;
  }
}

@media screen and (max-width: 1350px) and (min-width: 900px) {
  .container-search-inventory {
    max-width: 650px;
  }
}

@media screen and (max-width: 900px) and (min-width: 501px) {
  .container-search-inventory {
    max-width: 500px;
  }
}

.arrow-left-icon-search-inventory {
  position: absolute;
  top: 30px;
  left: 15%;
  font-size: 30px;
  color: var(--primary-sea-blue);
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .arrow-left-icon-search-inventory {
    position: relative;
    left: -43%;
  }
}

.search-inventory-components {
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
}

.search-inventory-components h2 {
  color: var(--primary-sea-blue) !important;
  letter-spacing: 0.5px;
  padding-bottom: 0 !important;
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 7.5rem !important;
}

.search-inventory-components span {
  color: var(--primary-sea-blue);
}

@media screen and (max-width: 500px) {
  .search-inventory-components h2 {
    font-size: 4rem !important;
  }
}

.filter-button {
  text-align: right;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: 0.7rem;
  max-width: 1024px;
  position: relative;
}

.filter-button span {
  cursor: pointer;
  position: relative;
}

.filter-button .filter-btn {
  position: relative;
  border: 0;
  background: transparent;
  color: var(--primary-sea-blue);
  padding: 0 15px;
  cursor: pointer;
}

.filter-button span::after {
  position: absolute;
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: var(--primary-sea-blue) transparent transparent transparent;
  top: 42%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Search Inventory from Courses */

.course-name-search {
  margin: 0;
  font-size: 1.5rem;
  color: var(--primary-sea-blue);
  font-weight: 400;
  letter-spacing: 0.8px;
  font-family: Lato;
  text-transform: uppercase;
}

.no-results-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-top: 5rem;
  color: var(--primary-sea-blue);
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 500px) {
  .no-results-message {
    font-size: 1.5rem;
  }
}

.lato {
  font-family: "Lato", sans-serif;
}

.light {
  font-weight: 300;
}

.claim-border-bottom {
  border-bottom: 1px solid var(--table-separator);
}

.no-color {
  color: unset !important;
}

.no-flex-direction {
  flex-direction: unset !important;
}

.grey-background {
  background-color: var(--primary-grey);
}

.white-border {
  border: 2px solid var(--primary-white) !important;
}

.verify-row {
  margin-bottom: 5px;
}

.drop-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}

.extra-padding {
  padding: 30px !important;
}

/* Logo Search Inventory */

@media screen and (max-width: 500px) {
  .logo-search-inventory.no-margin {
    margin-top: 0 !important;
  }
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: rgba(154, 154, 156, 0.6);
  border-radius: 5px;
}

html::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: rgba(113, 113, 122, 0.8);
}

html::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  background: transparent;
  margin: 2px;
}
