.request-course-components {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 1rem !important;
  margin-bottom: 3.5rem;
}

.request-course-components h2 {
  color: var(--primary-white);
  margin: 0;
  font-size: 6rem;
  padding-bottom: 3rem;
  margin-top: 1rem;
  font-family: "Thiccboi", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0px;
}

@media screen and (max-width: 500px) {
  .request-course-components h2 {
    font-size: 2rem;
    padding-bottom: 1.5rem;
  }
}

.request-course-components h2 span {
  font-weight: 100;
}

.steps {
  font-size: 7.5rem;
  color: var(--primary-white);
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .steps {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1124px) and (min-width: 501px) {
  .request-course-components h2 {
    font-size: 4rem;
  }
  .request-course-components h4 {
    width: 95%;
  }
}

.request-course-components .overtext-secondary {
  /* color: #005daa; */
  text-shadow: 0px 2px 3px #ffffff4f;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 100;
}

.where-request {
  font-size: 3rem;
  letter-spacing: 0.03em;
  font-weight: 500;
  color: var(--primary-white);
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  margin-top: 0;
}

@media screen and (max-width: 500px) {
  .where-request {
    font-size: 1.5rem;
  }
}

.secondary-missing-text-request {
  color: #005daa;
  font-weight: 500;
  text-shadow: 0px 2px 3px #ffffff4f;
  font-family: "Bebas Neue", sans-serif;
}

.smaller-text {
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px;
  color: var(--primary-white);
  margin-top: -15px;
  display: block;
}

.buttons-rescue {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;
}

.button-red-rescue {
  background-color: var(--primary-burnt-orange);
  width: 60%;
  /* max-width: 250px; */
  padding: 8px 4px;
  border: 2px solid transparent;
  font-weight: 500;
  letter-spacing: 1.5px;
  height: 50px;
  font-family: "Akira Expanded", sans-serif;
  padding: 0;
  font-size: 1rem;
}

@media screen and (max-width: 500px) {
  .button-red-rescue {
    font-size: 0.8rem;
  }
}

.second-button-rescue {
  width: 50%;
  padding: 0px;
  background-color: var(--primary-sea-blue);
  color: var(--primary-white);
  font-size: 0.9rem;
  letter-spacing: 0.08em;
  font-weight: 600;
  height: 30px;
  transform: scaleY(0.8);
}

@media screen and (max-width: 500px) {
  .second-button-rescue {
    font-size: 0.7rem;
  }
}

.button-red-rescue-3 {
  background-color: var(--primary-burnt-orange);
  width: 70%;
  /* max-width: 250px; */
  border: 2px solid transparent;
  font-weight: 500;
  letter-spacing: 1.5px;
  height: 50px;
  font-family: "Akira Expanded", sans-serif;
  padding: 0;
  font-size: 0.9rem;
}

.second-button-rescue-3 {
  width: 50%;
  padding: 0px;
  background-color: var(--primary-sea-blue);
  color: var(--primary-white);
  font-size: 0.9rem;
  letter-spacing: 0.08em;
  font-weight: 600;
  height: 30px;
  transform: scaleY(0.8);
}

@media screen and (max-width: 500px) {
  .button-red-rescue-3 {
    width: 90%;
    font-size: 0.7rem;
  }
  .second-button-rescue-3 {
    width: 60%;
  }
}

.button-red-rescue-5 {
  background-color: var(--primary-burnt-orange);
  width: 60%;
  /* max-width: 250px; */
  border: 2px solid transparent;
  font-weight: 500;
  letter-spacing: 1.5px;
  height: 50px;
  font-family: "Akira Expanded", sans-serif;
  padding: 0;
  font-size: 0.9rem;
}

.second-button-rescue-5 {
  width: 50%;
  padding: 0px;
  background-color: var(--primary-sea-blue);
  color: var(--primary-white);
  font-size: 0.9rem;
  letter-spacing: 0.08em;
  font-weight: 600;
  height: 30px;
  transform: scaleY(0.8);
}

@media screen and (max-width: 500px) {
  .button-red-rescue-5 {
    width: 90%;
    font-size: 0.7rem;
  }
  .second-button-rescue-5 {
    width: 60%;
    font-size: 0.9rem;
  }
}

.logo-request-course {
  margin-top: 1.5rem;
}

.button-request {
  max-width: 600px;
  margin: auto;
  margin-top: 2rem;
  width: 80%;
  height: 55px;
  font-family: "Akira Expanded", sans-serif;
  font-size: 0.8rem;
  padding: 0;
  letter-spacing: 0px;
}

.button-request:hover {
  background-color: var(--primary-sea-blue);
  transition: 0.3s ease;
}

/* claim disc success */

.claim-disc-success {
  margin-bottom: 0;
}

.claim-disc-success h2 {
  color: var(--primary-green);
  margin-top: 0;
  padding-bottom: 0.5rem;
}

.success-message {
  font-size: calc(1.3rem + 0.6vw);
  color: var(--primary-white);
  margin: 0;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .success-message {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .claim-disc-success h2 {
    font-size: 2rem !important;
  }
}

/* surrender disc success */

.red-button-surrender {
  height: 65px;
  max-width: 800px;
  width: 80%;
  letter-spacing: 1.5px;
  border-radius: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.red-button-surrender:hover {
  background-color: var(--primary-sea-blue);
  transition: 0.3s ease;
}

.success-message-surrender {
  color: var(--primary-white);
  font-size: 3rem;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
  line-height: 1.3;
}

@media screen and (max-width: 500px) {
  .success-message-surrender {
    font-size: 1.5rem;
    width: 90%;
  }

  .claim-disc-success h2 {
    font-size: 3rem !important;
  }
}

/* success report lost disc */

.success-message-report-lost {
  color: var(--primary-white);
  font-size: 3rem;
  width: 72%;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
  line-height: 1.3;
}

@media screen and (max-width: 500px) {
  .success-message-report-lost {
    font-size: 1.5rem;
    width: 90%;
  }

  .success-message-report-lost h4 {
    font-size: 3rem !important;
  }
}
