.select-box-report {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  border-radius: 0px;
}

.select-box-report select:focus {
  outline: none;
}

.select-box-report .arrow {
  position: relative;
}

.select-box-report .arrow.one:after {
  content: "";
  right: 10px;
}

.select-box-report .arrow:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: var(--secondary-safety-sky-blue) transparent transparent
    transparent;
  position: absolute;
  right: 21px;
  top: 42%;
  pointer-events: none;
}

.form-select-report::placeholder {
  color: var(--primary-sea-blue);
  opacity: 1;
}

.select-box-report .form-select-report {
  border-radius: 0;
  padding: 10px 6px;
  font-size: 16px;
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  height: 55px !important;
  font-family: inherit;
  font-weight: 400 !important;
}

.state {
  padding-left: 0;
}

.pe-0 {
  padding-right: 0;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
  padding-left: 0;
}

.col-10 {
  width: 100%;
  max-width: 600px;
}

.form-select-report {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--secondary-safety-sky-blue);
  background-color: #fff;
  border: 2px solid #ced4da;
  /* border-radius: 0.375rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

.form-select-request-input {
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  font-family: "Bebas Neue";
  font-weight: 400 !important;
  width: 100%;
  height: 55px !important;
  padding-left: 0.5rem;
  font-size: 15px;
  border-radius: 0;
}

.form-select-request-input::placeholder {
  color: var(--primary-sea-blue);
  font-size: 15px;
}

.col-8-request-course {
  flex: 0 0 auto;
  width: 65%;
  padding-left: 0;
  color: var(--primary-sea-blue);
}

.report-lost {
  margin-bottom: 0.6rem;
}

.report-lost input {
  border: none;
  background-image: none;
  appearance: none;
  position: relative;
  color: var(--primary-sea-blue);
  font-family: "Bebas Neue";
  font-weight: 400 !important;
  width: 100%;
  height: 55px !important;
  padding-left: 5px;
  border-radius: 0;
  font-size: 15px;
}

.report-lost input::placeholder {
  color: var(--primary-sea-blue);
  font-size: 15px;
}
