@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.main-wrapper {
  transition: 0.5s;
  right: 0;
  position: relative;
  height: auto;
  min-height: 100%;
  padding-top: 1rem;
}

.main-wrapper.open-sidebar {
  right: 210px;
  transition: 0.5s;
}

.disc-item.hidden,
.load-more.hidden {
  display: none;
}

.main-section button.stepbutton {
  background-color: var(--primary-burnt-orange);
  width: 140px;
  padding: 4px 0;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  border: 2px solid transparent;
}

.main-section button.stepbutton:hover {
  background-color: var(--primary-burnt-orange);
  width: 140px;
  padding: 4px 0;
  border: 2px solid transparent;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}

.inner-app-container {
  transition: margin-left 0.5s;
}

.inner-app-container.open-body {
  margin-left: -300px;
  transition: ease 0.5s;
}

.asidebar {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
  height: auto;
  transition: 0.5s;
  border-left: 2px solid var(--primary-burnt-orange);
  width: 0;
  visibility: hidden;
  background: var(--primary-grey);
}

.asidebar.open-sidebar {
  width: 300px;
  visibility: visible;
  transition: 0.5s;
  z-index: 100;
  max-width: 45%;
}

/* @media only screen and (max-width: 640px) {
  .asidebar.open-sidebar {
    width: 215px;
  }
} */

.sidebar-header {
  background: var(--primary-burnt-orange);
  text-align: center;
  padding: 15px 0;
}

.sidebar-header h2 {
  margin: 0;
  color: var(--primary-white);
  font-size: 1rem;
  font-family: "Akira Expanded", sans-serif;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 500px) {
  .sidebar-header h2 {
    font-size: 0.55rem;
  }
}

.filter-body {
  background: var(--primary-grey);
  overflow: hidden;
  overflow-y: auto;
  height: calc(100dvh - 40px);
  padding: 16px 10px;
}

.filter-body::-webkit-scrollbar {
  width: 5px;
}

.filter-body::-webkit-scrollbar-track {
  background: var(--primary-grey);
}

.filter-body::-webkit-scrollbar-thumb {
  background: var(--primary-burnt-orange);
}

.filter-body::-webkit-scrollbar-thumb:hover {
  background: var(--primary-burnt-orange);
}

.filter-body .accordion {
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

.collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, padding 0.4s ease;
}

.collapse.show {
  max-height: 190px;
  transition: max-height 0.4s ease, padding 0.4s ease;
}

.filter-body .accordion .accordion-button::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 6px 0 6px;
  border-color: var(--primary-sea-blue) transparent transparent transparent;
  transition: transform 0.3s;
  transform: rotate(180deg);
}

.filter-body .accordion .accordion-button.expanded::after {
  transform: inherit;
}

.filter-body .accordion .accordion-header .accordion-button {
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  color: var(--primary-black);
  font-size: 20px;
  letter-spacing: 0px;
  font-weight: 500;
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  /* --bs-accordion-border-radius: 0.375rem; */
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

/* @media (prefers-reduced-motion:reduce) {
  .accordion-button {
      transition: none
  }
} */

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

/* .collapse:not(.show) {
  display: none;
} */

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

.filter-body .accordion .accordion-item {
  border: 0;
  background: transparent;
  margin-bottom: 15px;
  height: auto;
  max-height: calc(30dvh - 50px);
}

.filter-body .accordion .accordion-item .accordion-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  overflow-y: auto;
  height: auto;
  max-height: calc(30dvh - 100px);
}

.filter-body .accordion .accordion-item .accordion-body ul::-webkit-scrollbar {
  width: 5px;
}

.filter-body
  .accordion
  .accordion-item
  .accordion-body
  ul::-webkit-scrollbar-track {
  background: var(--primary-white);
}

.filter-body
  .accordion
  .accordion-item
  .accordion-body
  ul::-webkit-scrollbar-thumb {
  background: var(--primary-sea-blue);
}

.filter-body
  .accordion
  .accordion-item
  .accordion-body
  ul::-webkit-scrollbar-thumb:hover {
  background: var(--primary-sea-blue);
}

.filter-body .accordion .accordion-item .accordion-body ul li {
  font-weight: 400;
  font-size: 20px;
  padding: 0px 0 0px 5px;
  position: relative;
}

.filter-body .accordion .accordion-item .accordion-body {
  padding: 0;
}

.filter-footer {
  margin-top: 1.5rem;
}

.filter-footer .filter-search {
  width: 100%;
  text-align: center;
  color: var(--primary-white);
  background: var(--primary-burnt-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  padding: 12px 8px;
  border: 2px solid var(--primary-burnt-orange);
  margin-bottom: 10px;
}

.filter-footer .filter-reset {
  border: 2px solid var(--primary-sea-blue);
  color: var(--secondary-safety-sky-blue);
  font-family: "Akira Expanded", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 0.9rem;
  letter-spacing: 1.5px;
  padding: 15px 5px;
}

.filter-footer .filter-reset:hover {
  color: var(--primary-sea-blue);
  transition: ease 0.2s;
}

@media screen and (max-width: 500px) {
  .filter-footer .filter-reset {
    font-size: 0.55rem;
  }
}

.skeleton-labels {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skeleton-label {
  background-color: #e0e0e0; /* Light gray for skeleton effect */
  height: 20px; /* Height of the skeleton label */
  margin-bottom: 10px; /* Space between skeleton labels */
  border-radius: 4px; /* Rounded corners */
  animation: shimmer 1.5s infinite ease-in-out;
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton-label {
  background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
  background-size: 400% 100%;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 44%; /* unsure why this works but it wasnt centering the text so i adjusted the checkbox */
  left: 0;
  height: 12px;
  width: 12px;
  transform: translateY(-50%);
  border: 2px solid var(--primary-sea-blue);
  background: var(--primary-sea-blue);
}

.checkcount {
  color: var(--primary-burnt-orange);
  padding-right: 5px;
}

.filter-text {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: auto;
  justify-content: space-between;
}

.filter-checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 6px;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
}

.filter-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.hidden {
  display: none;
}

/* When the checkbox is checked, add a blue background */
.filter-checkbox input:checked ~ .checkmark {
  background-color: var(--primary-sea-blue);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input[type="checkbox"]:checked+.checkmark:after {
  display: block;
}

/* Show the checkmark when checked */
.filter-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.filter-checkbox .checkmark:after {
  left: 3px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sort-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.switch {
  position: relative;
  /* display: inline-block; */
  width: 40px; /* Smaller width */
  height: 22px; /* Smaller height */
}

#sortToggle {
  margin-left: 10px;
}

.switch-label {
  font-size: 17px; /* Smaller font size for the labels */
  margin: 5px 5px 0px 5px; /* Space around the labels */
}

.switch.inventory {
  font-size: 1rem;
}

@media screen and (max-width: 500px) {
  .switch-label {
    font-size: 15px;
  }
  .switch.inventory {
    font-size: 0.7rem;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-burnt-orange); /* Red background color */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px; /* Adjusted for smaller height */
  width: 18px; /* Adjusted for smaller width */
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3; /* Blue background color */
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px); /* Adjusted for smaller width */
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* new changes close sidebar button */

.close-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}


.close-sidebar-button {
  font-size: 1.6rem;
  color: var(--primary-burnt-orange);
  border: none;
  background-color: var(--primary-grey);
  text-align: start;
  padding: 0;
  margin: 0;
  width: 10px;
  font-family: "Thiccboi", sans-serif !important;
  margin-bottom: 2px;
}

@media screen and (max-width: 500px) {
  .close-sidebar-button {
    font-size: 1.4rem;
  }
}

.info-icon {
  cursor: pointer;
  font-size: 10px;
  margin-bottom: 4px;
}

.tooltip {
  display: inline-block;
  position: absolute;
  background-color: #5c5c5c;
  color: #fff;
  padding: 5px 5px 4px 5px;
  margin-bottom: 40px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1000;
  margin-left: 10px;
  white-space: nowrap;
}

/* Small Screens */

@media screen and (max-width: 375px) and (min-width: 321px) {
  .filter-body {
    padding: 8px 5px;
  }
  
  .switch-label {
    font-size: 14px;
  }
  
  .switch.inventory {
    font-size: 0.6rem;
  }
  
  .filter-body .accordion .accordion-header .accordion-button {
    font-size: 18px;
  }
  
  .filter-body .accordion .accordion-item .accordion-body ul li {
    font-weight: 400;
    padding: 0px 0 0px 1px;
  }

  .fab-menu {
    width: 210px;
    height: 210px;
  }

  .fab-menu-container-mobile {
    width: 210px;
    height: 210px;
  }
}

/* Very small screens */

@media screen and (max-width: 320px) and (min-width: 300px) {
  .icon-logo-store {
    width: 100px;
  }

  .search-inventory-components h2 {
    margin-top: 0.3rem;
    font-size: 3.5rem !important;
  }

  .filter-body {
    padding: 8px 5px;
  }

  .switch-label {
    font-size: 13px;
  }

  .switch.inventory {
    font-size: 0.55rem;
  }

  .close-sidebar-button {
    font-size: 1.3rem;
    margin-bottom: 0px;
  }

  .filter-body .accordion .accordion-header .accordion-button {
    font-size: 16px;
  }

  .accordion {
    margin-top: -15px;
  }

  .filter-body .accordion .accordion-item {
    border: 0;
    background: transparent;
    margin-bottom: 5px ;
    height: auto;
    max-height: calc(30dvh - 50px);
  }

  .filter-body .accordion .accordion-item .accordion-body ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    overflow-y: auto;
    height: auto;
    max-height: calc(35dvh - 95px);
  }

  .filter-body .accordion .accordion-item .accordion-body ul li {
    font-weight: 400;
    padding: 0px 0 0px 1px;
  }

  .filter-text {
    font-size: 13px;
  }

  .course-section {
    height: calc(180dvh - 450px);
  }

  .course-list {
    min-height: 90px;
  }

  .disc-info {
    min-height: 90px;
  }

  .fab-menu {
    width: 175px;
    height: 175px;
  }
  
  .fab-menu-container-mobile {
    width: 175px;
    height: 175px;
  }
}