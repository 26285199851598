.buttons-rescue-failure {
  max-width: 400px;
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.custom-rescue-flow-failure h3 {
  font-size: 3rem;
  padding-bottom: 1.5rem;
}

.custom-rescue-flow-failure .smaller-text-rescue {
  font-size: 1rem;
}

@media screen and (max-width: 500px) {
  .custom-rescue-flow-failure h3 {
    font-size: 1.5rem;
  }

  .custom-rescue-flow-failure h2 {
    font-size: 3rem;
  }

  .smaller-text {
    line-height: 1.5rem;
  }

  .buttons-rescue-failure {
    width: 90%;
    padding: 0;
  }

  .button-red-rescue-failure {
    padding: 0;
  }

  .second-button-rescue-failure {
    width: 30%;
  }
}

.smaller-text {
  font-size: 1rem;
}

.button-red-rescue-failure {
  height: 50px;
  font-family: "Akira Expanded", sans-serif;
  padding: 0;
  font-size: 0.8rem;
  letter-spacing: 1.5px;
  width: 520px;
}

@media screen and (max-width: 500px) {
  .button-red-rescue-failure {
    font-size: 0.55rem;
    width: 330px;
    letter-spacing: 0.5px;
  }
}

.second-button-rescue-failure {
  width: 65%;
  padding: 0px;
  background-color: var(--primary-sea-blue);
  color: var(--primary-white);
  font-size: 0.9rem;
  letter-spacing: 0.08em;
  font-weight: 600;
  height: 35px;
  transform: scaleY(0.8);
}
