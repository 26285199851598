.rescue-flow-components {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 1rem !important;
  margin-bottom: -0;
}

.rescue-flow-components h2 {
  color: var(--primary-white);
  margin: 0;
  font-size: 7.5rem;
  padding-bottom: 1rem;
}

@media screen and (max-width: 850px) {
  .rescue-flow-components h2 {
    font-size: 6rem;
  }
}

@media screen and (max-height: 820px) {
  .rescue-flow-components h2 {
    font-size: 6.5rem;
  }
}

@media screen and (max-height: 760px) {
  .rescue-flow-components h2 {
    font-size: 5.5rem;
  }
}

@media screen and (max-width: 500px) {
  .rescue-flow-components h2 {
    font-size: 2.5rem;
  }
}

.rescue-flow-components h2 span {
  font-weight: 100;
}

.steps {
  font-size: 7.5rem;
  color: var(--primary-white);
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .steps {
    font-size: 2.5rem;
  }
}

.rescue-flow-components .overtext-secondary {
  /* color: var(--secondary-safety-sky-blue); */
  text-shadow: 0px 2px 3px #ffffff4f;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 100;
}

.where-rescue {
  font-size: 2rem;
  letter-spacing: 0.03em;
  font-weight: 500;
  color: var(--primary-white);
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 0;
}

.secondary-missing-text {
  color: var(--secondary-safety-sky-blue);
  font-weight: 500;
}

.smaller-text-rescue {
  font-size: 0.7rem;
  margin: 0px;
  padding: 0px;
  color: var(--primary-white);
  display: block;
  letter-spacing: 0.08em;
}

.buttons-rescue {
  max-width: 400px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;
}

.button-red-rescue {
  background-color: var(--primary-burnt-orange);
  width: 75%;
  /* max-width: 250px; */
  padding: 8px 4px;
  border: 2px solid transparent;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  height: 50px;
}

.second-button-rescue {
  width: 55%;
  padding: 0px;
  background-color: var(--primary-sea-blue);
  color: var(--primary-white);
  font-size: 14px;
  letter-spacing: 0.08em;
  font-weight: 300;
  height: 30px;
}
