.buttons-rescue-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  max-width: 450px;
  gap: 1rem;
  margin-top: 1rem;
}

.red-button-popup {
  width: 90%;
  max-width: 450px;
  height: 60px !important;
}

.blue-button-popup {
  height: 40px;
  font-size: 15px;
  font-weight: 300;
  margin-top: 0.5rem;
  padding: 9px;
  width: 80%;
}

/* .verify-row {
  color: var(--primary-black);
  width: 65%;
  max-width: 400px;
  margin: 0;
} */
