.discs {
  max-width: 95%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
/* 

.card-container-discs {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 5px 0px;
  overflow-x: auto;
  gap: 1.5rem;
  max-width: 1350px;
  margin: auto;
}

@media screen and (max-width: 500px) {
  .card-container-discs {
    gap: 1.5rem;
    max-width: 800px;
    width: 100%;
    overflow-x: visible;
  }
}

.card-container-discs::-webkit-scrollbar {
  width: 10px;
  height: 15px;
  background-color: var(--primary-white);
}

.card-container-discs::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 1px;
} */

.card-container-claim-discs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 5px;
  column-gap: 20px; /* Adjust horizontal gap */
  row-gap: 20px; /* Adjust vertical gap */
}

.card-container-claim-single-disc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
